export const colors = {
  primary: '#fbf7f5',
  logo: '#F8F8F8',
  primaryDark: 'rgba(134, 134, 134, 1)',
  primaryDarkDisabledText: 'rgba(0, 0, 0, 0.26)',
  secondaryDark: '#332a24',
  error: '#F93822',
  white: '#ffffff',
  black: '#000000',
  grey500: '#9e9e9e',
  greyDisabled: '#C6C6C6',
  grey300: '#e0e0e0',
  grey100: '#f5f5f5',
  overlay: 'rgba(51, 42, 36, 0.7)',
  white10: 'rgba(1, 1, 1, 0.1)',
  borderGrey: 'rgb(118, 118, 118, 0.4)',
  arrowGrey: 'rgb(118, 118, 118)',
  borderBlack: 'rgba(0, 0, 0, 0.87)',
  loaderBg: 'rgba(51, 42, 36, 0.7)',
  scrollHover: 'rgba(51, 42, 36, 0.4)',
  buttonText: '#FCF7F5',
  text: '#000000',
  cyanDark: '#404D56',
  textHover: '#636363',
  textActive: '#000000',
  cardBackground: 'rgb(201, 201, 201)',
  backgroundOverlay: 'rgba(0, 0, 0, 0.25)',
  backgroundOverlay2: 'rgba(0, 0, 0, 0.6)',
  selectBackground: '#F7F2F0',
  selectHover: '#E7E0DE',
  flagBorder: '#e6e6e6',
  secondaryBackground: '#EEDBCD',
  transparent: 'rgba(0, 0, 0, 0)',
} as const;
