import { css } from 'styled-components';
import { Breakpoints, Colors } from '.';
import { BoxProps } from '~/components/wrappers/Box';
import { colors } from './colors';

const fontWeights = {
  fw300: '300',
  fw400: '400',
  fw500: '500',
  fw600: '600',
  fw700: '700',
  fw800: '800',
  fw900: '900',
};

export type FontWeight = keyof typeof fontWeights;

export const fontFamilies: Record<string, FontFamily> = {
  HalvarBreit: 'HalvarBreit',
};

export type FontFamily = 'HalvarBreit';

export type FontVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'p'
  | 'custom';

type FontStyle = {
  scale: string;
  size: string;
  lineHeight?: string;
};

type FontSettings = {
  fontFamily?: FontFamily;
  letterSpacing?: string;
  fontWeight?: FontWeight;
  fontStyle?: string;
  textTransform?: string;
  color?: string;
};

export interface TypographyProps extends BoxProps {
  variant: FontVariant;
  fontWeight?: FontWeight;
  color?: Colors;
  decoration?: string;
  children?: React.ReactNode;
  textTransform?: string;
  letterSpacing?: string;
  fontFamily?: FontFamily;
  interactive?: boolean;
  interactiveSecondary?: boolean;
  fontSize?: number | string;
  lineHeight?: number | string;
  tag?: 'p' | 'h4' | 'h3' | 'h2' | 'h1' | 'span';
  id?: string;
}

// TODO: add default settings when design updates
export const typographySettings: Partial<Record<FontVariant, FontSettings>> = {
  h1: {
    fontWeight: 'fw900',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  h2: {
    fontWeight: 'fw900',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  h3: {
    fontWeight: 'fw900',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  h4: {
    fontWeight: 'fw900',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  h5: {
    fontWeight: 'fw900',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  h6: {
    fontWeight: 'fw900',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  p: {
    fontWeight: 'fw500',
    fontFamily: 'HalvarBreit',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  subtitle1: {
    fontFamily: 'HalvarBreit',
    fontWeight: 'fw700',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  subtitle2: {
    fontFamily: 'HalvarBreit',
    fontWeight: 'fw500',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
    color: colors.text,
  },
  // These settings are applied to all breakpoints
};

export const typographyVariants: Record<
  FontVariant,
  Partial<Record<Breakpoints, Partial<FontStyle>>>
> = {
  h1: {
    sm: {
      size: '2rem', // 32px
      lineHeight: '2.3163rem', // 37.06px
    },
    md: {
      size: '3rem', // 48px
      lineHeight: '3.4737rem', //55.57px
    },
    xl: {
      size: '4rem', // 64px
      lineHeight: '4.6319rem', // 74.11px
    },
    xxl: {
      size: '4rem', // 64px
      lineHeight: '4.6319rem', // 74.11px
    },
  },
  h2: {
    sm: {
      size: '2rem', // 32px
      lineHeight: '2.3163rem', // 37.06px
    },
    xl: {
      size: '3rem', // 48px
      lineHeight: '3.4737rem', //55.57px
    },
    xxl: {
      size: '3rem', // 48px
      lineHeight: '3.4737rem', //55.57px
    },
  },
  h3: {
    sm: {
      size: '1.25rem',
      lineHeight: '1.4475rem',
    },
    md: {
      size: '2rem', // 32px
      lineHeight: '2.3163rem', // 37.06px
    },
    lg: {
      size: '3rem', // 48px
      lineHeight: '3.4737rem', //55.57px
    },
    xl: {
      size: '3rem', // 48px
      lineHeight: '3.4737rem', //55.57px
    },
    xxl: {
      size: '3rem', // 48px
      lineHeight: '3.4737rem', //55.57px
    },
  },
  h4: {
    sm: {
      size: '1.25rem', //20px
      lineHeight: '1.4475rem', //23.16px
    },
    md: {
      size: '2rem', // 32px
      lineHeight: '2.3163rem', // 37.06px
    },
    xl: {
      size: '2.5rem', // 40px
      lineHeight: '2.895rem', // 46.32px
    },
    xxl: {
      size: '2.5rem', // 40px
      lineHeight: '2.895rem', // 46.32px
    },
  },
  h5: {
    sm: {
      size: '1.5rem', // 24px
      lineHeight: '1.7369rem', // 27.79px
    },
    xl: {
      size: '2rem', // 32px
      lineHeight: '2.3163rem', // 37.06px
    },
    xxl: {
      size: '2rem', // 32px
      lineHeight: '2.3163rem', // 37.06px
    },
  },
  h6: {
    xl: {
      size: '1rem', // 16px
      lineHeight: '1.6rem', // 25.6px
    },
    xxl: {
      size: '1rem', // 16px
      lineHeight: '1.1581rem', // 18.52px
    },
  },
  subtitle1: {
    xl: {
      size: '1.25rem', // 20px
      lineHeight: '1.625rem', // 26px
    },
    xxl: {
      size: '1.25rem', // 20px
      lineHeight: '1.625rem', // 26px
    },
  },
  subtitle2: {
    sm: {
      size: '0.875rem', // 14px
      lineHeight: '1.3rem', // 20.8px
    },
    xl: {
      size: '1rem', // 16px
      lineHeight: '1.3rem', // 20.8px
    },
    xxl: {
      size: '1rem', // 16px
      lineHeight: '1.3rem', // 20.8px
    },
  },
  p: {
    sm: {
      size: '0.75rem', // 12px
      lineHeight: '0.975rem', // 15.6px
    },
    xl: {
      size: '1rem', // 16px
      lineHeight: '1.3rem', // 20.8px
    },
    xxl: {
      size: '1rem', // 16px
      lineHeight: '1.3rem', // 20.8px
    },
  },

  custom: {},
} as const;

const HEADING_ELEMENTS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export const isHeadingElement = (variant: FontVariant) =>
  variant ? HEADING_ELEMENTS.includes(variant) : false;

const fontVariantHasProperty = (
  variant: FontVariant,
  breakpoint: Breakpoints,
  property: keyof FontStyle,
) => !!typographyVariants[variant]?.[breakpoint]?.[property];

const generateCss = (
  {
    variant,
    lineHeight,
    fontWeight,
    letterSpacing,
    textTransform,
    fontFamily,
    fontSize,
    color,
  }: TypographyProps,
  breakpoint: Breakpoints,
) => css`
  ${fontVariantHasProperty(variant, breakpoint, 'size') &&
  css`
    font-size: ${fontSize || typographyVariants?.[variant]?.[breakpoint]?.size};
  `}

  letter-spacing: ${letterSpacing ||
  typographySettings[variant]?.letterSpacing ||
  'normal'};
  font-style: ${typographySettings[variant]?.fontStyle || 'normal'};
  font-family: ${fontFamily ||
  typographySettings[variant]?.fontFamily ||
  fontFamilies.HalvarBreit};
  line-height: ${lineHeight ||
  typographyVariants[variant]?.[breakpoint]?.lineHeight};
  font-weight: ${fontWeights[
    fontWeight || typographySettings[variant]?.fontWeight || 'fw400'
  ]};
  text-transform: ${textTransform ||
  typographySettings[variant]?.textTransform ||
  'initial'};
  color: ${({ theme }) =>
    (color && theme.colors[color]) ||
    typographySettings[variant]?.color ||
    'inherit'};
`;

const generateMediaQuery = (
  props: TypographyProps,
  breakpoint: Breakpoints | undefined,
) => {
  if (breakpoint)
    return css`
      @media ${({ theme }) => theme.breakpoints[breakpoint]} {
        ${generateCss(props, breakpoint)}
      }
    `;

  return css`
    ${generateCss(props, 'xxl')}
  `;
};

/**
 * Generates CSS for given breakpoints
 */
const queryBreakpoints: Array<Breakpoints> = ['xxl', 'xl', 'lg', 'md', 'sm']; // order must be large -> small!
export const typographyCss = (props: TypographyProps) => css`
  ${queryBreakpoints
    .map((b) => generateMediaQuery(props, b))
    .concat(
      // dont wrap lgDesktop in any @media query, make it as default
      generateMediaQuery(props, undefined),
    )};
`;
