import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '~/theme';

export interface Spacing {
  p?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  px?: string;
  py?: string;
  m?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  my?: string;
  mx?: string;
}

export interface BoxProps extends Spacing {
  id?: string;
  width?: string;
  className?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  position?: string;
  backgroundColor?: Colors;
  textAlign?: string;
  borderRadius?: string;
  display?: string;
  cursor?: string;
  zIndex?: number;
  boxShadow?: string;
  border?: string;
  overflow?: string;
  opacity?: number | string;
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
}

interface BoxComponent extends BoxProps {
  children?: React.ReactNode;
  boxAs?: keyof JSX.IntrinsicElements;
  onClick?: () => void;
  onAnimationEnd?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Box = forwardRef<HTMLDivElement, BoxComponent>(
  ({ children, onClick, ...props }, ref) => {
    const { boxAs, ...restProps } = props;

    return (
      <StyledBox ref={ref} as={boxAs} {...restProps} onClick={onClick}>
        {children}
      </StyledBox>
    );
  },
);

export const spacingCss = (p: Spacing) => css`
  padding: ${p.p};
  padding-top: ${p.pt || p.py};
  padding-bottom: ${p.pb || p.py};
  padding-left: ${p.pl || p.px};
  padding-right: ${p.pr || p.px};
  margin: ${p.m || 0};
  margin-top: ${p.mt || p.my};
  margin-bottom: ${p.mb || p.my};
  margin-left: ${p.ml || p.mx};
  margin-right: ${p.mr || p.mx};
`;

export const boxCss = (p: BoxProps) => css`
  width: ${p.width};
  max-width: ${p.maxWidth};
  min-width: ${p.minWidth};
  height: ${p.height};
  min-height: ${p.minHeight};
  max-height: ${p.maxHeight};
  position: ${p.position};
  text-align: ${p.textAlign};
  border-radius: ${p.borderRadius};
  display: ${p.display};
  cursor: ${p.cursor};
  z-index: ${p.zIndex || 'initial'};
  box-shadow: ${p.boxShadow};
  border: ${p.border};
  overflow: ${p.overflow};
  opacity: ${p.opacity ?? 1};
  top: ${p.top};
  left: ${p.left};
  right: ${p.right};
  bottom: ${p.bottom};
`;

const StyledBox = styled.div<BoxProps>`
  ${spacingCss};
  ${boxCss};
`;
