import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import {
  TypographyProps,
  isHeadingElement,
  typographyCss,
} from '~/theme/typography';
import { boxCss, spacingCss } from '../wrappers/Box';

export const Typography = forwardRef<HTMLDivElement, TypographyProps>(
  ({ children, tag, ...styleProps }, ref) => (
    <StyledTypography
      as={
        tag ?? (isHeadingElement(styleProps.variant) ? styleProps.variant : 'p')
      }
      ref={ref}
      {...styleProps}
    >
      {children}
    </StyledTypography>
  ),
);

const StyledTypography = styled.p<TypographyProps>`
  ${spacingCss};
  ${boxCss};
  ${typographyCss};

  ${({ color, decoration, theme }) => css`
    color: ${theme.colors[color || 'black']};
    decoration: ${decoration};
  `}
`;
